export const structure = {
  data: [
    {
      label: 'Model ID',
      variableName: 'modelId',
      type: 'select',
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'CP158-AF',
          value: 'CP158-AF'
        },
        {
          label: 'CS158-AF',
          value: 'CS158-AF'
        },
        {
          label: 'CO158-AF',
          value: 'CO158-AF'
        },
        {
          label: 'CP137-AF',
          value: 'CP137-AF'
        },
        {
          label: 'CO137-AF',
          value: 'CO137-AF'
        },
        {
          label: 'CP258-AF',
          value: 'CP258-AF'
        }
      ]
    },
    {
      label: 'Tickets ID',
      variableName: 'ticketNo',
      type: 'input'
    },
    {
      label: 'user email',
      variableName: 'userEmail',
      type: 'input'
    },
    {
      label: 'Tel',
      variableName: 'shippingPhone',
      type: 'input'
    },
    {
      label: 'Country',
      variableName: 'shippingCountry',
      type: 'select',
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'United States',
          value: 'United States'
        },
        {
          label: 'Canada',
          value: 'Canada'
        },
        {
          label: 'Mexico',
          value: 'Mexico'
        }
      ]
    },
    {
      label: 'B/N number',
      variableName: 'bnNumber',
      type: 'input',
    },
    {
      label: 'Submission time',
      variableName: 'dateRange',
      type: 'time',
    },
    {
      label: 'shipping',
      variableName: 'shipping',
      type: 'select',
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Unshipped',
          value: 0
        },
        {
          label: 'Shipped',
          value: 1
        },
      ]
    },
    // {
    //   label: 'order ID',
    //   variableName: 'shippingOrderNo',
    //   type: 'input',
    // },
    {
      label: 'Operator account',
      variableName: 'operatorAccount',
      type: 'input',
    },
    {
      label: 'product image',
      variableName: 'submitProductImageLater',
      type: 'select',
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Completed',
          value: 0
        },
        {
          label: 'Incomplete',
          value: 1
        },
      ]
    },
    {
      label: 'B/N repeat',
      variableName: 'isRepeatBnCodeLine',
      type: 'select',
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Yes',
          value: 1
        },
        {
          label: 'No',
          value: 0
        },
      ]
    }
  ],
  searchBtn: 'Search',
  clearBtn: 'Reset'
}

export const ticketStatusMapper = [
  'draft', // 0 草稿
  'Rejected Application', // 1 待提交
  'Registration Under Review', // 2 已提交待审核
  'Upload Proof of Cut Cord', // 3 待补充
  'Cut Cord Picture Under Review', // 4 已补充待审核
  'Final Confirmation', // 5 已审核
  'Shipment Processed', // 6 分拣中
  'Finish', // 7 已完成
  'Cancel' // 8 已取消
]

export const baseReviewTemplate = [
  {
    label: 'Submitted B/N doesn\'t match product',
    value: '<strong>Upon review, the batch number (B/N) submitted appears to be incomplete/incorrect.</strong> Please double-check the B/N on the underside of the air fryer and update the registration form. Once revised, our team will review and verify the updated information.'
  },
  {
    label: 'Submitted Product color doesn\'t match product',
    value: '<strong>The color you selected does not match the color we have on file for this batch number.</strong> Please double-check the selected color and make sure it matches the color of your air fryer.'
  },
  {
    label: 'Submitted Model No. doesn\'t match product',
    value: '<strong>We have identified that the model number is incorrect, which may alter the replacement air fryer.</strong> Please update your registration with the correct model number according to the sticker label on the bottom of your air fryer. Once the information is resubmitted, our team will review the submission.'
  },
  {
    label: 'Missing written B/N on paper',
    value: '<strong>Please write your product\'s batch number (B/N) on a separate piece of paper and include this written B/N in all product photos.</strong> The B/N label should also be visible and legible in the photos of the underside of your air fryer.'
  },
  {
    label: 'Product B/N or written B/N is not legible',
    value: '<strong>The pictures you submitted do not clearly and legibly show your product\'s batch number (B/N).</strong> Please submit new pictures of the underside of your air fryer in which the full label and written down B/N are legible.'
  },
  {
    label: 'Product Label is not legible',
    value: '<strong>We aim to verify the product information to ensure it matches the registration requirements (product model, B/N). However, we are unable to view the air fryer information because the image is blurred.</strong> Please update your registration with a clear photo of the bottom portion of your air fryer. Once submitted, our team will review the submission again.'
  },
  {
    label: 'Written B/N is not matching up',
    value: '<strong>The pictures you submitted include a batch number (B/N) that does not match the one on your product\'s label or your registration.</strong> Please re-write your B/N on a separate piece of paper and submit new photos, including the written B/N in each photo.'
  },
  {
    label: 'Cut Cord is not clear/legible',
    value: '<strong>Based on the pictures you submitted, we were unable to verify that your air fryer\'s cord has been cut.</strong> Please resubmit pictures that clearly show your product\'s cut cord; the cut cord should be visible in each picture.'
  },
  {
    label: 'Cord is not cut',
    value: '<strong>Based on the pictures you submitted, it appears the power cord on your product has not been cut.</strong> Please resubmit pictures that clearly show your product\'s cut cord. For additional image requirements, please log into your registration.'
  },
  {
    label: 'Incomplete Images',
    value: '<strong>We are missing one or more required pictures of your recalled air fryer.</strong> Please log into your registration to view image requirements and upload any missing pictures.'
  }
]
