<template>
  <div class="hole-on">
    <span>whether to hang</span>
    <a-select v-model="form.hold" :disabled="holddisabled" style="width: 120px;margin: 0 8px;" @change="handleChange">
      <a-select-option :value="1">
        hold
      </a-select-option>
      <a-select-option :value="0">
        unhold
      </a-select-option>
    </a-select>
    <span style="color: red;">{{holdReason}}</span>
    <a-modal
      title="Please select reasons"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model 
      :model="form"
      :rules="rules"
      ref="form"
      >
        <a-form-model-item prop="holdReason">
          <a-checkbox-group v-model="form.holdReason" @change="handleChangeCheckbox">
            <div v-for="(item, index) in holdReasonList" :key="index">
              <a-checkbox :value="item">
                {{item}}
              </a-checkbox>
              <a-input 
                v-if="index === holdReasonList.length - 1" 
                v-model="otherReason"
                :disabled="otherDisable"
                :maxLength="300" 
                style="width: 260px;"/>
            </div>
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ['ticketId', 'holdReason', 'hold', 'status'],
  data () {
    return {
      otherReason: '',
      role: Number(JSON.parse(localStorage.getItem('userInfo'))?.role),
      form: {
        hold: 0,
        holdReason: []
      },
      rules: {
        holdReason: [
          { required: true, message: 'Please select your hold on reason.', trigger: 'change' }
        ]
      },
      visible: false,
      holdReasonList: [
        'Duplicate BN number (BN号重复)',
        'Uncut power cord (未剪线)',
        'Wrong model (型号错)',
        'User assign color (用户指定产品颜色)',
        'Other (其它)'
      ]
    }
  },
  computed: {
    otherDisable () {
      return !this.form.holdReason.includes('Other (其它)')
    },
    holddisabled () { 
      return  this.status === 7 || !(this.role === 2 || this.role === 4 || this.role === 6)
    }
  },
  watch: {
    hold: {
      handler (value) {
        if (!value) return
        this.form.hold = this.hold
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleChangeCheckbox () { 
      if (this.otherDisable) this.otherReason = ''
    },
    handleChange (value) {
      if (value === 1) {
        this.visible = true
      } else {
        this.otherReason = ''
        this.form.holdReason = []
        this.handleHoldOn()
      }
    },
    handleCancel () {
      this.form.hold = 0
      this.visible = false
    },
    handleOk () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.handleHoldOn()
      });
    },
    async handleHoldOn () {
      const { hold, holdReason } = this.form

      const onterReason = (this.otherReason ? `Other (其它): ${this.otherReason}` : 'Other (其它)')
      
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/updateTicketHoldStatus', {
        ticketId: this.ticketId,
        hold,
        holdReason: holdReason.join(';').replace('Other (其它)', onterReason)
      })
      if (res.code === 0) {
        this.$emit('handleHoldSuccess')
        this.visible = false
      }
    }
  }
}
</script>

<style>
.hole-on{
  margin: 0 30px;
}
</style>