<template>
  <a-modal v-model="visible" :footer="null" width="90%">
    <a-table :columns="columns" :data-source="tableData" :rowKey="record => record.ticketNo">
      <template slot="ticketNo" slot-scope="text, record">
        <a-button style="display:block; padding: 0" @click="handleGoTicket(record.ticketId)" type="link">{{text}}</a-button>
      </template>
      <template slot="pictureSlot1" slot-scope="text, record">
        <img
          v-if="record.bnCodeImageUrl"
          width="80px"
          class="zoom-in form-img"  
          :src="text"
          @error="errorImg('bnCodeImageUrl', record)"
          alt="Customer Uploaded Image" 
          preview
          preview-text="Customer Uploaded Image" />
          <span v-else>-</span>
      </template>
      <template slot="pictureSlot2" slot-scope="text, record">
        <img
          v-if="record.productFrontViewImageUrl"
          width="80px"
          class="zoom-in form-img"  
          :src="text"
          @error="errorImg('productFrontViewImageUrl', record)"
          alt="Customer Uploaded Image" 
          preview
          preview-text="Customer Uploaded Image" />
          <span v-else>-</span>
      </template>
      <template slot="pictureSlot3" slot-scope="text, record">
        <img
          v-if="record.productBackViewImageUrl"
          width="80px"
          class="zoom-in form-img"  
          :src="text"
          @error="errorImg('productBackViewImageUrl', record)"
          alt="Customer Uploaded Image" 
          preview
          preview-text="Customer Uploaded Image" />
          <span v-else>-</span>
      </template>
    </a-table> 
  </a-modal>
</template>

<script>
import { replaceImgUrl } from "@/utils/utils.js"
import { ticketStatusMapper } from '../var'

const columns = [
  {
    title: 'REGISTRATION NUMBER',
    dataIndex: 'ticketNo',
    key: 'ticketNo',
    scopedSlots: { customRender: 'ticketNo' }
  },
  {
    title: 'Hold',
    dataIndex: 'hold',
    key: 'hold',
    customRender: (text) => {
      return text === 1 ? 'hold': ''
    }
  },
  {
    title: 'STAGE',
    dataIndex: 'status',
    key: 'status',
    customRender: (text) => {
      return ticketStatusMapper[text]
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'PHONE NUMBER',
    dataIndex: 'shippingPhone',
    key: 'shippingPhone',
  },
  {
    title: 'Name',
    dataIndex: 'shippingFirstName',
    key: 'shippingFirstName',
    customRender: (text, row) => {
      return `${row.shippingFirstName} ${row.shippingLastName}`
    }
  },
  {
    title: 'Address 1',
    dataIndex: 'shippingAddressLine1',
    key: 'shippingAddressLine1',
  },
  {
    title: 'Address 2',
    dataIndex: 'shippingAddressLine2',
    key: 'shippingAddressLine2',
  },
  {
    title: 'City/Town',
    dataIndex: 'shippingCity',
    key: 'shippingCity',
  },
  {
    title: 'State/Province',
    dataIndex: 'shippingState',
    key: 'shippingState',
  },
  {
    title: 'COUNTRY',
    dataIndex: 'shippingCountry',
    key: 'shippingCountry',
  },
  {
    title: 'PRODUCT OF UNDERSIDS',
    dataIndex: 'bnCodeImageUrl',
    key: 'bnCodeImageUrl',
    scopedSlots: { customRender: 'pictureSlot1' }
  },
  {
    title: 'PICTURE OF FRONT',
    dataIndex: 'productFrontViewImageUrl',
    key: 'productFrontViewImageUrl',
    scopedSlots: { customRender: 'pictureSlot2' }
  },
  {
    title: 'PICTURE OF BACK',
    key: 'productBackViewImageUrl',
    dataIndex: 'productBackViewImageUrl',
    scopedSlots: { customRender: 'pictureSlot3' }
  },
];
export default {
  data () { 
    return {
      visible: false,
      columns,
      tableData: []
    }
  },
  methods: {
    handleGoTicket (id) {
        const routeUrl = this.$router.resolve({
        path:`/ticket/detail/${id}`
      })
      window.open(routeUrl.href, '_blank')
    },
    show (data) {
      this.visible = true
      this.tableData = data
      this.$previewRefresh()
    },
    errorImg (name, record) {
      // console.log(name, record)
      // const url = record[name]

      // if (url.indexOf('https://vesync-cloud-recall-cn.s3.cn-northwest-1.amazonaws.com.cn') > -1) {
      //   this.replaceUrl(name, record)
      // } else { 
      //   record[name] = '';
      // }
      record[name] = replaceImgUrl(record[name])
      // 这里要延时处理
      setTimeout(()=>{  
        this.$previewRefresh();
      },1000)
    },
    // replaceUrl (name, record) {
    //   const ChinaUrl = 'https://vesync-cloud-recall-cn.s3.cn-northwest-1.amazonaws.com.cn'
    //   const url = PAGECONF.imgApi
    //   record[name] = record[name].replace(ChinaUrl, url)
    //   setTimeout(()=>{  
    //     this.$previewRefresh();
    //   },1000)
    // },
  }
}
</script>