<template>
  <a-upload
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    action="#"
    :before-upload="beforeUpload"
    :customRequest="customRequest"
    :disabled="disabled"
  >
    <img v-if="imageUrl" :src="imageUrl" @error="errorImg()" alt="avatar" width="86" height="90" />
    <div v-else>
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">
        Upload
      </div>
    </div>
  </a-upload>
</template>
<script>
import { replaceImgUrl } from "@/utils/utils.js"
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    defaultImg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
    };
  },
  watch: {
    defaultImg (value) {
      this.imageUrl = value
    }
  },
  created () { 
    this.imageUrl = this.defaultImg
  },
  methods: {
    errorImg () { 
      this.imageUrl = replaceImgUrl(this.imageUrl)
    },
    // 自定义上传
    async customRequest (params) {
      if (params.file.size / 1024 / 1024 > 5) {
        this.$message.error(`Image must smaller than 5MB!`)
        return
      }
      this.uploadHttp(params)
    },
    async uploadHttp (params) {
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('file', params.file)
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/uploadImage', formData)
        if (res.code === 0) {
          this.imageUrl = res.data
          this.$emit('uploadSuccess', res.data)
        }
      } catch (error) {
        //
      } finally {
        this.loading = false
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('Image must smaller than 5MB!');
      }
      return isJpgOrPng && isLt5M;
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 86px;
  height: 90px;

}
.ant-upload.ant-upload-select-picture-card > .ant-upload{
  padding: 0;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
