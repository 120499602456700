<template>
  <div>
    <h3>REMARKS</h3>
    <remark-form :ticketId="ticketId" @handleUpdateSuccess="getRemarks"></remark-form>
    <remark-list :remarklist="remarklist" :ticketId="ticketId" @handleUpdateSuccess="getRemarks"></remark-list>
  </div>
</template>

<script>
import RemarkForm from './components/RemarkForm.vue'
import RemarkList from './components/RemarkList.vue'
export default {
  components: {
    RemarkForm,
    RemarkList
  },
  props: {
    ticketId: {
      type: Number,
      default: null
    }
  },
  data  () {
    return {
      remarklist: []
    }
  },
  methods: {
    async getRemarks () { 
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/listRemark', { ticketId: this.ticketId })
      if (res.code === 0) { 
        this.remarklist = res.data || []
      }
    }
  },
  mounted  () {
    this.getRemarks()
  },
}
</script>

<style lang='less' scoped>

</style>