<template>
  <div class="remark-form">
    <!-- <div>Current Operator:</div>
    <div>{{username}}</div> -->
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item prop="classification" label="Classification:">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.classification" placeholder="please select" :dropdownClassName="remarkItem ? '' : 'remark-form-option-style'">
          <a-select-option :value="item" v-for="(item, index) in classificationList" :key="index">{{item}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="remark">
        <a-textarea
          :maxLength="3000"
          v-model.trim="form.remark"
          :auto-size="{ minRows: 5, maxRows: 5 }"
          style="resize: none;"
        />
      </a-form-model-item>
      <a-form-model-item style="text-align: right">
        <a-button type="primary"  @click="handleSubmit">
          Submit
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import btnIsPermissions from '../../../utils/btnPermissions'
import { classificationList }  from '../../../assets/js/listData'

export default {
  components: {},
  props: {
    ticketId: {
      type: Number,
      default: null
    },
    remarkItem: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    remarkItem: {
      handler(value) {
        if (value) {
          this.form = { ...value }
        }
      },
      immediate: true
    }
  },
  data  () {
    return {
      username: JSON.parse(localStorage.getItem('userInfo'))?.username,
      form: {
        remark: '',
        classification: undefined
      },
      rules: {
        remark: [
          { required: true, message: 'Please fill in the remarks.', trigger: 'blur' }
        ],
        classification: [
          { required: true, message: 'Please select.', trigger: 'change' }
        ]
      },
      classificationList
    }
  },
  methods: {
    async handleSubmit () {
      const isPermision = await btnIsPermissions('remarkRoles')
      if (!isPermision) return

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const {remark, id, classification} = this.form
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/submitRemark', {
            remark,
            classification,
            id,
            ticketId: this.ticketId
          })
          if (res.code === 0) {
            this.$message.success('Successful operation');
            this.$emit('handleUpdateSuccess')
            this.form.remark = ''
            this.form.classification = undefined
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang='less'>

  .remark-form-option-style{
    left: 0 !important;
    width: 410px !important;
    margin-left: -100px;
  }
</style>