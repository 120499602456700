<template>
  <div>
    <h4>PURCHASE METHOD</h4>
    <div class="info-item">
      <div>PURCHASE METHOD:</div>
      <div>{{ticketDetail.purchaseMethod}}</div>
    </div>
    <div class="info-item">
      <div>PURCHASED MARKETPLACE:</div>
      <div>{{ticketDetail.purchaseChannel}}</div>
    </div>
    <div class="info-item">
      <div>ORDER NUMBER/INVOICE NUMBER:</div>
      <div class="flex" style="align-items: center;">
        <div>
          {{ticketDetail.purchaseOrderNo || '-'}}{{ ticketDetail.purchaseInvoiceNo? '/' + ticketDetail.purchaseInvoiceNo : '' }}
        </div>
        <a-button 
          @click="() => visible=true"
          :disabled="![2, 4, 6].includes(role)"
          type="link" 
          shape="circle" 
          icon="edit" 
          size="small" 
          class="ml8"/>
      </div>
      <template v-if="ticketDetail.repeatPurchaseOrderNoTicketList && ticketDetail.repeatPurchaseOrderNoTicketList.length">
        <div style="color: red;margin-top: 12px;">WARNING: Duplicate Order Number!</div>
        <a-button style="display:block; padding: 0" type="link" @click="handleShowBnModal">check the details</a-button>
      </template>
    </div>
    <div class="info-item">
      <div>PURCHASED DATE:</div>
      <div>{{timeFormatUSA(ticketDetail.purchaseTime, 'MM-DD-YYYY') || '-'}}</div>
    </div>
    <div class="info-item">
      <div>HOW DID YOU FIND OUT ABOUT THE RECALL?</div>
      <div>{{ ticketDetail.recallChannel }} <span v-if="ticketDetail.recallChannelOther">{{ '-' + ticketDetail.recallChannelOther}}</span></div>
    </div>

    <a-modal
      title="Edit order number"
      :visible="visible"
      okText="save"
      @ok="handleOk"
      @cancel="visible=false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <a-form-model-item ref="ruleForm" label="Order number" prop="purchaseOrderNo">
          <a-input v-model="form.purchaseOrderNo" />
        </a-form-model-item>
        </a-form-model>
    </a-modal>
    <duplicate-b-n-dialog ref="duplicateBN"></duplicate-b-n-dialog>
  </div>
</template>

<script>
import { timeFormatUSA } from '@/utils/utils.js'
import DuplicateBNDialog from './DuplicateBNDialog.vue'

export default {
  components: { DuplicateBNDialog },
  props: {
    ticketDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible (val) {
      if (val) { 
        this.form.purchaseOrderNo = this.ticketDetail.purchaseOrderNo
        if (this.$refs.ruleForm) this.$refs.ruleForm.validateField('purchaseOrderNo')
      }
    }
  },
  data () {
    return {
      visible: false,
      form: {
        purchaseOrderNo: ''
      },
      rules: {
        purchaseOrderNo: [
          { required: true, message: 'Please input Order number', trigger: 'blur' }
        ]
      },
      role: Number(JSON.parse(localStorage.getItem('userInfo'))?.role),
    }
  },
  methods: {
    timeFormatUSA,
    handleShowBnModal () { 
      this.$refs.duplicateBN.show(this.ticketDetail.repeatPurchaseOrderNoTicketList)
    },
    handleOk () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await this.$http.post('/platform/admin/ticketAdminManage/v1/updateTicketPurchaseInfo', {
            ticketId: this.ticketDetail.id,
            purchaseOrderNo: this.form.purchaseOrderNo
          })
          this.$emit('handleUpdateOrderNum')
          this.visible = false
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang='less' scoped>
.info-item{
  margin-top: 12px;
  div{
    line-height: 1;
  }
}
</style>