<template>
  <ul class="base-info">
    <li>
      <h4>TICKET STATUS/STAGE:</h4>
      <div>
        {{ ticketStatusMapper[ticketDetail.status] || '-' }}
      </div>
    </li>
    <li>
      <h4>TICKET/CLAIM NUMBER:</h4>
      <div>
        {{ ticketDetail.ticketNo }}
        <span class="primaryColor pointer" @click="handleCopy(ticketDetail.ticketNo)">Copy</span>
      </div>
    </li>
    <li>
      <h4>SUBMISSION TIME:</h4>
      <div>{{ timeFormatUSA(ticketDetail.submissionTime, 'YYYY-MM-DD HH:mm:ss', 'America/New_York') || '-' }}</div>
    </li>
    <li class="solution">
      <h4>SOLUTION:</h4>
      <div>{{ ticketDetail.treatment ? 'repair' : 'replace' }}</div>
    </li>
    <li v-if="role !== 3">
      <h4>REPLACEMENT MODEL NO:</h4>
      <div>{{modelIdFormat}}</div>
    </li>
    <li v-if="role !== 3">
      <h4>REPLACEMENT COLOR:</h4>
      <div>{{colorFormat}}</div>
    </li>
    <li>
      <h4>ORDER NUMBER:</h4>
      {{ ticketDetail.shippingOrderNo || '-' }} 
      <span v-if="ticketDetail.shippingOrderNo" class="primaryColor pointer" @click="handleCopy(ticketDetail.shippingOrderNo)">Copy</span>
    </li>
    <li>
      <h4>LOGISTICS COMPANY:</h4>
      <div>{{ ticketDetail.logisticsCompany || '-' }}</div>
    </li>
    <li>
      <h4>LOGISTICS NO:</h4>
      <div>
        {{ ticketDetail.logisticsNo || '-' }}
        <span v-if="ticketDetail.logisticsNo" class="primaryColor pointer" @click="handleViewProgress(ticketDetail.logisticsNo)">View progress</span>
      </div>
    </li>
    <li>
      <h4>DELIVERY TIME:</h4>
      <div>{{ timeFormatUSA(ticketDetail.deliveryTime) || '-' }}</div>
    </li>
  </ul>
</template>

<script>
import { ticketStatusMapper } from '../var'
import { timeFormatUSA } from '@/utils/utils.js'
export default {
  props: {
    ticketDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      ticketStatusMapper,
      role: Number(JSON.parse(localStorage.getItem('userInfo'))?.role),
    }
  },
  methods: {
    timeFormatUSA,
    handleCopy (text) {
      this.$copyText(text).then(() => {
        this.$message.success('copy success')
      }).catch(() => {
        this.$message.error('copy fail')
      })
    },
    handleViewProgress (No) {
      window.open(`https://t.17track.net/en?v=2#nums=${No}`)
    },
  },
  computed: {
    modelIdFormat () {
      if (this.ticketDetail && this.ticketDetail.shippingSkuInfoList) {
        return this.ticketDetail?.shippingSkuInfoList[0]?.modelId
      } else { 
        return '-'
      }
    },
    colorFormat () { 
      if (this.ticketDetail && this.ticketDetail.shippingSkuInfoList) {
        return this.ticketDetail?.shippingSkuInfoList[0]?.color
      } else { 
        return '-'
      }
    }
  }
}
</script>

<style lang='less' scoped>
.base-info{
  list-style: none;
  font-size: 14px;
  padding: 0;
  // display: flex;
  li{
    // flex: 1;
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 12px;
  }
  .solution{
    flex: 0 0 100px;
  }
}
</style>