<template>
  <div class="mainPage">
    <div class="flex">
      <p class="pageTitle"> {{ ticketDetail.status === 2 || ticketDetail.status === 4 ? 'Ticket Review' : 'Ticket Detail' }}</p>
      <hold-on :ticketId="id" :hold="ticketDetail.hold" :holdReason="ticketDetail.holdReason" :status="ticketDetail.status" @handleHoldSuccess="getTicketDetail(id)"></hold-on>
    </div>
    <div class="pageWrap flex">
        <div class="flex1" style="padding: 20px 20px 20px 0">
          <a-tag color="#2db7f5" v-if="ticketDetail.type === 1">Difficult Tickets</a-tag>
          <base-info :ticketDetail="ticketDetail"></base-info>
          <a-divider />
          <div class="flex">
            <div class="flex1 mgr8">
              <h4>CUSTOMER INFORMATION</h4>
              <a-form-model
              ref="shipForm"
              layout="vertical"
              :rules="shippingRules"
              :model="shippingInfo"
              class="shipForm"
              >
                <a-form-model-item label="First Name" prop="shippingFirstName">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingFirstName"></a-input>
                </a-form-model-item>
                <a-form-model-item label="Last Name" prop="shippingLastName">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingLastName"></a-input>
                </a-form-model-item>
                <a-form-model-item label="Phone Number" prop="shippingPhone" v-if="userData || role === 0">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingPhone"></a-input>
                </a-form-model-item>
                <a-form-model-item label="Email" prop="Email" v-if="ticketDetail.email">
                  <a-input :disabled="true" v-model="ticketDetail.email"></a-input>
                </a-form-model-item>
                <a-divider />
                <h4>SHIPPING INFORMATION</h4>
                <a-form-model-item label="Address 1" prop="shippingAddressLine1" v-if="userData || role === 0">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingAddressLine1" :maxLength="35"></a-input>
                </a-form-model-item>
                <a-form-model-item label="Address 2"  v-if="userData || role === 0">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingAddressLine2" :maxLength="35"></a-input>
                </a-form-model-item>
                <a-form-model-item label="City/Town" prop="shippingCity">
                  <a-select mode="combobox" :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingCity" :filterOption="false" @popupScroll="handleCityScroll" show-search @search="handleSearchCity" @dropdownVisibleChange="initCityOption">
                    <a-select-option v-for="item in cityOptionList" :key="item" :value="item">{{item}}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="State/Province" prop="shippingState">
                  <a-select :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingState" :filterOption="false" @popupScroll="handleStateScroll" show-search  @search="handleSearchState" @dropdownVisibleChange="initStateOption">
                    <a-select-option v-for="item in stateOptionList" :key="item" :value="item">{{item}}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="Country" prop="shippingCountry">
                  <a-select v-model="shippingInfo.shippingCountry" :disabled="shippingInfoEditDisable || !userData">
                    <a-select-option value="United States">
                      United States
                    </a-select-option>
                    <a-select-option value="Canada">
                      Canada
                    </a-select-option>
                    <a-select-option value="Mexico">
                      Mexico
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="Zipcode" prop="shippingZipCode">
                  <a-input :disabled="shippingInfoEditDisable || !userData" v-model="shippingInfo.shippingZipCode"></a-input>
                </a-form-model-item>
                <a-form-model-item label="Tax ID/RFC：" prop="taxId" v-if="shippingInfo.shippingCountry==='Mexico' && [2,4,6].includes(role)">
                  <a-input v-model="shippingInfo.taxId" :disabled="!(ticketDetail.status !== 8 && ticketDetail.submitProductImageLater === 1 && !ticketDetail.taxId)"></a-input>
                </a-form-model-item>
                <a-form-model-item label=" " :colon="false">
                  <a-button style="width: 100%" :disabled="shippingInfoEditDisable || !userData" type="primary" class="center-btn mgt12" @click="handleUpdateShipInfo">
                    Update address information
                  </a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
            <div class="flex1 mgr8">
              <product-info :ticketDetail="ticketDetail"></product-info>
              <a-divider />
              <purchase-method :ticketDetail="ticketDetail" @handleUpdateOrderNum="getTicketDetail(id)"></purchase-method>
            </div>
            <div class="flex1 mgr8">
              <h4>UPLOADED PRODUCT PICTURES</h4>
              <p>Click to Enlarge</p>
              <div class="flex">
                <div class="flex1">
                  <img class="zoom-in form-img" src="../../assets/img/BN.png" alt="" preview preview-text="EXAMPLE">
                  <div>EXAMPLE</div>
                </div>
                <div class="flex1">
                  <Upload class="ml16" @uploadSuccess="handleUploadSuccess($event, 'bnCodeImageUrl')" :disabled="addressDisable" :defaultImg="ticketDetail.bnCodeImageUrl" v-if="isUpload"/>
                  <img 
                    class="zoom-in form-img"  
                    @error="errorImg('bnCodeImageUrl')"
                    :src="ticketDetail.bnCodeImageUrl" 
                    alt="BN" 
                    v-else-if="ticketDetail.bnCodeImageUrl" 
                    preview 
                    preview-text="Customer Uploaded Image">
                  <div v-if="!(ticketDetail.status === 8 && !ticketDetail.bnCodeImageUrl)">Customer Uploaded Image</div>
                </div>
              </div>

              <p style="font-size: 12px;">UPLOADED PRODUCT PICTURE: FRONT</p>
              <div class="flex">
                <div class="flex1">
                  <img class="zoom-in form-img" src="../../assets/img/front.png" alt="front picture" border="1" preview preview-text="EXAMPLE">
                  <div>EXAMPLE</div>
                </div>
                <div class="flex1">
                  <Upload class="ml16" @uploadSuccess="handleUploadSuccess($event, 'productFrontViewImageUrl')" :disabled="addressDisable" :defaultImg="ticketDetail.productFrontViewImageUrl" v-if="isUpload"/>
                  <img 
                    class="zoom-in form-img" 
                    :src="ticketDetail.productFrontViewImageUrl" 
                    alt="front picture" 
                    border="1" 
                    v-else-if="ticketDetail.productFrontViewImageUrl" 
                    preview 
                    preview-text="Customer Uploaded Image"
                    @error="errorImg('productFrontViewImageUrl')" 
                    >
                  <p v-if="!(ticketDetail.status === 8 && !ticketDetail.productFrontViewImageUrl)">Customer Uploaded Image</p>
                </div>
              </div>

              <p style="font-size: 12px;">UPLOADED PRODUCT PICTURE: BACK</p>
              <div class="flex">
                <div class="flex1">
                  <img class="zoom-in form-img" src="../../assets/img/back.png" alt="front picture" border="1" preview preview-text="EXAMPLE">
                  <div>EXAMPLE</div>
                </div>
                <div class="flex1">
                  <Upload class="ml16" @uploadSuccess="handleUploadSuccess($event, 'productBackViewImageUrl')" :disabled="addressDisable" :defaultImg="ticketDetail.productBackViewImageUrl" v-if="isUpload"/>
                  <img 
                    class="zoom-in form-img" 
                    :src="ticketDetail.productBackViewImageUrl" 
                    alt="front picture" 
                    border="1" 
                    v-else-if="ticketDetail.productBackViewImageUrl" 
                    preview 
                    preview-text="Customer Uploaded Image"
                    @error="errorImg('productBackViewImageUrl')" 
                    >
                  <p v-if="!(ticketDetail.status === 8 && !ticketDetail.productBackViewImageUrl)">Customer Uploaded Image</p>
                </div>
              </div>

              <a-button style="width: 100%;" @click="handleSubmitImg" :loading="uploadLoading" :disabled="!(ticketDetail.status === 2 && ticketDetail.submitProductImageLater === 1) || addressDisable" type="primary" class="center-btn">
                Update picture information
              </a-button>
            </div>
            <div class="flex1 mgr8" v-if="role !== 3">
              <!-- <h4>UPLOADED CUT CORD PICTURE</h4>
              <p>Click to Enlarge</p>
              <div class="flex">
                <div class="flex1">
                  <img class="zoom-in form-img" src="../../assets/img/destroy.png" alt="destroy picture" border="1" preview preview-text="EXAMPLE">
                  <div>EXAMPLE</div>
                </div>
                <div class="flex1">
                  <Upload @uploadSuccess="handleUploadSuccess($event, 'destroyImageUrl')" :defaultImg="ticketDetail.destroyImageUrl" v-if="ticketDetail.status === 3"/>
                  <img class="zoom-in form-img" :src="ticketDetail.destroyImageUrl" alt="destroy picture" border="1" v-else-if="ticketDetail.destroyImageUrl" preview preview-text="Customer Uploaded Image">
                  <div v-if="ticketDetail.status === 3 || ticketDetail.destroyImageUrl">Customer Uploaded Image</div>
                </div>
              </div>
              <a-button style="width: 100%;" @click="handleSubmitImg" :loading="uploadLoading" :disabled="!(ticketDetail.status === 3)" type="primary" class="center-btn">
                Update picture information
              </a-button>
              <a-divider /> -->
              <h4>REPLACEMENT PRODUCT</h4>
              <a-button v-if="ticketDetail.status === 2 || ticketDetail.status === 4 || ticketDetail.status === 5 || adminPermissionAction" @click="showEditModal('Add')" type="primary" class="mbt12">
                Add Product
              </a-button>
              <!-- <a-form-model layout="vertical" class="shipForm mbt12" v-for="(item, index) in ticketDetail.shippingSkuInfoList" :key="index">
                <a-form-model-item label="SKU ID:">
                  {{item.skuId}}
                  <a-icon 
                    v-if="ticketDetail.status === 2 || ticketDetail.status === 4" 
                    class="ml8" 
                    type="edit"
                    theme="twoTone"
                    two-tone-color="#00C1BC"
                    @click="showEditModal(item)" />
                </a-form-model-item>
                <a-form-model-item label="MODEL ID:">
                  {{item.modelId}}
                </a-form-model-item>
                <a-form-model-item label="PRODUCT COLOR:">
                  {{item.color}}
                </a-form-model-item>
                <a-form-model-item label="QUANTITY:">
                  {{item.quantity}}
                </a-form-model-item>
              </a-form-model> -->

              <a-descriptions v-for="(item, index) in ticketDetail.shippingSkuInfoList" :key="index" :column="1" bordered size="small" class="mb16">
                <a-descriptions-item label="SKU ID">
                  {{item.skuId}}
                  <a-icon 
                    v-if="ticketDetail.status === 2 || ticketDetail.status === 4 || ticketDetail.status === 5 || adminPermissionAction" 
                    class="ml8" 
                    type="edit"
                    theme="twoTone"
                    two-tone-color="#00C1BC"
                    @click="showEditModal('Edit', item)" />

                  <a-popconfirm
                    v-if="ticketDetail.status === 2 || ticketDetail.status === 4 || ticketDetail.status === 5 || adminPermissionAction"
                    title="Do you want to continue?"
                    ok-text="Continue"
                    cancel-text="Cancel"
                    @confirm="handleDelReplacement(item)"
                  >
                    <a-icon 
                      class="ml8 pointer" 
                      type="delete"
                      theme="twoTone"
                      two-tone-color="#00C1BC" />
                  </a-popconfirm>

                </a-descriptions-item>
                <a-descriptions-item label="MODEL ID">{{item.modelId}}</a-descriptions-item>
                <a-descriptions-item label="PRODUCT COLOR">{{item.color}}</a-descriptions-item>
                <a-descriptions-item label="QUANTITY">{{item.quantity}}</a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
          <!-- <a-divider />
          <div>
            <h4>ADDITIONAL NOTE</h4>
            {{ticketDetail.note || '-'}}
          </div> -->
          <a-divider />
          <div class="opr-block" v-if="ticketDetail.status === 2 || ticketDetail.status === 4 || adminPermissionAction">
            <a-form-model
              ref="reviewForm"
              :rules="reviewRules"
              :model="reviewInfo"
              :label-col="{ span: 6 }" 
              :wrapper-col="{ span: 18 }"
              class="review-form">
              <h1>Review:<span style="font-size: 12px;margin-left: 12px;">Authorized Personnel Only</span></h1>

                  <a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 10 }" label="Findings of audit" prop="reviewResult">
                    <a-select v-model="reviewInfo.reviewResult" :disabled="reviewFormDisable">
                      <a-select-option :value="1">Approve</a-select-option>
                      <a-select-option :value="0">Reject</a-select-option>
                    </a-select>
                  </a-form-model-item>
                <div v-if="reviewInfo.reviewResult === 0">
                  <a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Reply Template" >
                    <a-select
                      @change="handleTemplateChange"
                      mode="multiple"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                      <a-select-option
                        v-for="item in (ticketDetail.status === 2 || adminPermissionAction) ? baseReviewTemplate : []"
                        :key="item.value"
                        :value="item.value">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              <a-form-model-item label="Message to Customer" prop="reviewComment">
                <a-textarea style="resize: none;" v-model.trim="reviewInfo.reviewComment" :autosize="{ minRows: 2, maxRows: 4 }" :disabled="reviewFormDisable"/>
              </a-form-model-item>
              <a-form-model-item label="Priority shipping：" v-if="ticketDetail.status === 5">
                  <a-switch 
                    v-model="ticketDetail.priority" 
                    @change="handlPriorityShipping" 
                    :loading="switchLoading"
                    @click="() => switchLoading=true"
                  />
              </a-form-model-item>
              <a-button class="mgr80" @click="$router.go(-1)">Go back</a-button>
              <a-button type="primary" class="mgr80" @click="handleReview" :loading="submitLoading">Submit</a-button>
              <a-button @click="handleUpdateTicketType" type="primary" class="mgr80" v-if="ticketDetail.status !== 7">
                {{  ticketDetail.type === 0 ? 'ESCALATE TICKET' : 'CONVERT TO COMMON TICKET' }}
              </a-button>
              <a-popconfirm
                title="Do you want to continue?"
                ok-text="Continue"
                cancel-text="Cancel"
                @confirm="handleDelete"
              >
                <a-button class="mgr80" type="danger" v-if="ticketDetail.status < 7" :loading="deleteLoading">Delete</a-button>
              </a-popconfirm>
              <a-button style="margin-top: 20px;" type="primary" @click="handleColorConfirm">{{ticketDetail.colorConfirm == 1 ? 'Not Confirmed' : 'Color Confirm' }}</a-button>
            </a-form-model>
          </div>
          <div class="opr-block" v-else>
            <a-form-model-item label="Priority shipping：" v-if="ticketDetail.status === 5">
                <a-switch 
                  v-model="ticketDetail.priority" 
                  @change="handlPriorityShipping" 
                  :loading="switchLoading"
                  @click="() => switchLoading=true"
                />
            </a-form-model-item>
            <a-button class="mgr80" @click="$router.go(-1)">Go back</a-button>
            <a-popconfirm
                title="Do you want to continue?"
                ok-text="Continue"
                cancel-text="Cancel"
                @confirm="handleActivation"
              >
                <a-button type="primary" class="mgr80" v-if="ticketDetail.status === 8">Activation</a-button>
            </a-popconfirm>
            <a-button @click="handleUpdateTicketType" type="primary" class="mgr80" v-if="ticketDetail.status !== 7">
              {{  ticketDetail.type === 0 ? 'ESCALATE TICKET' : 'CONVERT TO COMMON TICKET' }}
            </a-button>
            <a-popconfirm
                ok-text="Confirm"
                cancel-text="Cancel"
                @confirm="handleReissue"
              >
              <div slot="title">
                <p>Warning!</p>
                <div>
                  Whether to confirm re shipment. Once confirmed, the system will re generate a shipment order.
                </div>
              </div>
              <a-button class="mgr80" type="primary" v-if="ticketDetail.status === 7">REISSUE</a-button>
            </a-popconfirm>
            <a-popconfirm
                title="Do you want to continue?"
                ok-text="Continue"
                cancel-text="Cancel"
                @confirm="handleDelete"
              >
                <a-button class="mgr80" type="danger" v-if="ticketDetail.status < 7" :loading="deleteLoading">Delete</a-button>
              </a-popconfirm>
              <a-button class="mgr80" type="danger" v-if="ticketDetail.status === 6" @click="handleRollbackToReviewed">Rollback to Review Completed</a-button>
              <a-button style="margin-top: 20px;" type="primary" @click="handleColorConfirm">{{ticketDetail.colorConfirm == 1 ? 'Not Confirmed' : 'Color Confirm' }}</a-button>
          </div>

          <h4 style="margin-top: 20px;">TICKET HISTORY RECORD</h4>
          <edit-record-list :editRecordingList="ticketDetail.editRecordingList"/>
        </div>

      <div class="remark-box">
        <remark :ticketId="Number(id)"></remark>
      </div>
    </div>


    <a-modal v-model="editModalVisible" :title="editModalType + ' Sku'" @ok="addOrUpdateSkuId">
      <a-input v-model="editSkuId" />
    </a-modal>
  </div>
</template>

<script>
import { timeFormatUSA, replaceImgUrl } from '@/utils/utils.js'
import { cityList } from '@/assets/js/cities.js'
import { stateList } from '@/assets/js/state.js'
import { ticketStatusMapper, baseReviewTemplate } from './var'
import Upload from '@/components/upload.vue'
import { Modal } from 'ant-design-vue';
import EditRecordList from './EditRecordList.vue'
import Remark from './Remark.vue'
import BaseInfo from './components/BaseInfo.vue'
import ProductInfo from './components/ProductInfo.vue'
import PurchaseMethod from './components/PurchaseMethod.vue'
import btnIsPermissions, { btnPermissionsRoles } from '../../utils/btnPermissions'
import HoldOn from './components/HoldOn.vue'

export default {
  name: 'TicketDetail',
  components: { Upload, EditRecordList, Remark, BaseInfo, ProductInfo, PurchaseMethod, HoldOn },
  data () {
    var validateReviewComment = (rule, value, callback) => {
      if (this.reviewInfo.reviewResult === 0 && !value) {
        callback(new Error('Required in case of rejection.'))
      } else {
        callback()
      }
    }
    return {
      ChinaS3Url: 'https://files-recall.vesync.cn',
      skuidDisable: false,
      role: Number(JSON.parse(localStorage.getItem('userInfo'))?.role),
      visible: false,
      uploadLoading: false,
      ticketStatusMapper,
      baseReviewTemplate,
      cityOptionList: cityList.slice(0, 10),
      stateOptionList: stateList.slice(0, 10),
      cityOptionPage: 1,
      stateOptionPage: 1,
      id: this.$route.params.id,
      loading: true,
      ticketDetail: {},
      shippingInfo: {
        shippingFirstName: '',
        shippingLastName: '',
        shippingPhone: '',
        shippingAddressLine1: '',
        shippingAddressLine2: '',
        shippingCity: '',
        shippingState: '',
        shippingZipCode: '',
        shippingCountry: '',
        taxId: ''
      },
      shippingRules: {
        shippingFirstName: [{ required: true, message: 'please enter', trigger: 'blur' }],
        shippingLastName: [{ required: true, message: 'please enter', trigger: 'blur' }],
        shippingPhone: [{ required: true, message: 'please enter', trigger: 'blur' }],
        shippingAddressLine1: [{ required: true, message: 'please enter', trigger: 'blur' }],
        // shippingCity: [{ required: true, message: 'please select', trigger: ['blur', 'change'] }],
        shippingState: [{ required: true, message: 'please select', trigger: ['blur', 'change'] }],
        shippingZipCode: [{ required: true, message: 'please enter', trigger: 'blur' }],
        taxId: [
          { pattern: /^(\b[A-Z][AEIOU][A-Z]{2}[0-9]{2}(?:0[1-9]|1[0-2])(?:[1-2][0-9]|0[1-9]|3[0-1])[A-Z0-9]{2}[0-9A]\b)|(\b[a-z][aeiou][a-z]{2}[0-9]{2}(?:0[1-9]|1[0-2])(?:[1-2][0-9]|0[1-9]|3[0-1])[a-z0-9]{2}[0-9a]\b)$/, message: 'There is a problem with your tax ID/RFC number. Please re-enter your number, double-check it for accuracy, and ensure it is 13 characters long.', trigger: 'blur' }
        ]
      },
      productImgInfo: {
        bnCodeImageUrl: '',
        productFrontViewImageUrl: '',
        productBackViewImageUrl: '',
        destroyImageUrl: ''
      },
      reviewInfo: {
        reviewResult: 1,
        reviewComment: ''
      },
      reviewRules: {
        reviewResult: [{ required: true, message: 'please select', trigger: ['blur', 'change'] }],
        reviewComment: [
          // { max: 500, message: 'length should be less then 500', trigger: 'blur' },
          { validator: validateReviewComment, message: 'Required in case of rejection.', trigger: 'blur' }
        ]
      },
      reviewList: [],
      currentRow: {},
      editModalVisible: false,
      editModalType: 'Add',
      editSkuId: '',
      deleteLoading: false,
      submitLoading: false,
      switchLoading: false
    }
  },
  computed: {
    isUpload () { 
      return this.ticketDetail.status === 2 && this.ticketDetail.submitProductImageLater === 1
    },
    shippingInfoEditDisable () {
      const valid = this.ticketDetail.status === 2 || this.ticketDetail.status === 4 || this.ticketDetail.status === 5 || (this.ticketDetail.status === 1 && this.role === 2)
      return !valid
    },
    addressDisable () {
      const rolesList = btnPermissionsRoles.editTicketRoles
      return !rolesList.includes(this.role)
    },
    reviewFormDisable () { 
      const rolesList = btnPermissionsRoles.reviewRoles
      return !rolesList.includes(this.role)
    },
    userData () {
      const rolesList = btnPermissionsRoles.userData
      return rolesList.includes(this.role)
    },
    adminPermissionAction () { 
      return this.role === 2 && (this.ticketDetail.status === 1 || this.ticketDetail.status === 5)
    }
  },
  mounted () {
    this.getTicketDetail(this.id)
    // this.getReviewList(this.id)
  },
  methods: {
    handlPriorityShipping () {
      const onOk = async () => {
        try {
          await this.$http.post('/platform/admin/ticketAdminManage/v1/updatePriorityShippingStatus', {ticketId: this.id, priority: Number(this.ticketDetail.priority)})
        } finally {
          this.switchLoading = false
        }
      }
      const onCancel = () => {
        this.ticketDetail.priority = !this.ticketDetail.priority
        this.switchLoading = false
      }
      this.$confirm({
        title: 'Are you sure to turn on the switch?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
        onCancel,
      });
    },
    async handleColorConfirm () { 
      try {
        const colorConfirm = Number(this.ticketDetail.colorConfirm === 1) ? 0 : 1
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/colorConfirm', {ticketId: this.id, colorConfirm})
        if (res.code === 0) { 
          this.getTicketDetail(this.id)
        }
      } catch (error) {
        // error
      }
    },
    async handleRollbackToReviewed () {
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/rollbackToReviewed', {ticketId: this.id})
        if (res.code === 0) { 
          this.getTicketDetail(this.id)
        }
      } catch (error) {
        // error
      }
      
    },
    errorImg (name) {
      // const url = this.ticketDetail[name]

      // if (url.indexOf(this.ChinaS3Url) > -1) {
      //   this.replaceUrl(name)
      // } else { 
      //   this.ticketDetail[name] = '';
      // }
      this.ticketDetail[name] = replaceImgUrl(this.ticketDetail[name])
      // 这里要延时处理
      setTimeout(()=>{  
        this.$previewRefresh();
      },1000)
    },
    // replaceUrl (name) {
    //   const url = PAGECONF.imgApi
    //   this.ticketDetail[name] = this.ticketDetail[name].replace(this.ChinaS3Url, url)
    // },
    // 补发货
    async handleReissue () {
      const isPermision = await btnIsPermissions('reissueTicket')
      if (!isPermision) return

      if (this.ticketDetail.shippingCountry === 'Mexico' && !this.ticketDetail.taxId) {
         Modal.warning({
            cancelText: 'Ok',
            title: () => 'Tax ID/RFC incomplete, please contact Customer Service to reissue.'
          })
        return
      }

      const sockRes = await this.$http.post('/platform/admin/ticketAdminManage/v1/checkStock', {ticketId: this.id})
      if (sockRes.data === 0) {
        if (this.role !== 2) {
          Modal.warning({
            cancelText: 'Ok',
            title: () => 'Sorry, the product you selected is out of stock!'
          })
          return
        } else {
          this.$confirm({
            title: 'Out of stock, do you want to continue modifying?',
            content: '',
            onOk:() => {
              this.reissueHttp()
            },
            onCancel() {},
          });
        }
      } else {
        this.reissueHttp()
      }
    },
    async reissueHttp() {
      try {
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/reissue', {ticketId: this.id})
          if (res.code === 0) {
            this.getTicketDetail(this.id)
          }
        } catch (error) {
          //
        }
    },
    async handleUpdateTicketType () {
      const { id, type } = this.ticketDetail

      const permissionName = (type === 1 ? 'toCommonTicket' : 'escalateTicket')

      const isPermision = await btnIsPermissions(permissionName)
      if (!isPermision) return

      const text = type === 0 ? 'The upgrade was successful, and the list is being returned for you.' : 'Convert to common ticket success'
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/updateTicketType', { ticketId: id, type: type === 0 ? 1 : 0 })
      if (res.code === 0) { 
        this.$message.success(text)
        if (type === 0) {
          this.$router.go(-1)
        } else { 
          this.getTicketDetail(this.id)
        }
      }
    },
    async handleActivation () {
      const isPermision = await btnIsPermissions('activationTicket')
      if (!isPermision) return

      const { id } = this.ticketDetail
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/activeTicket', { ticketId: id })
        if (res.code === 0) { 
          this.getTicketDetail(this.id)
        }
      } catch (error) {
        if (error.code === 430012) { 
          Modal.warning({
            cancelText: 'Ok',
            title: () => 'The B/N code of this work order has been used, and the system refuses to activate this work order.'
          })
        }
      }
    },
    async handleSubmitImg () {
      const isPermision = await btnIsPermissions('editTicketRoles')
      if (!isPermision) return

      const { id } = this.ticketDetail
      const { bnCodeImageUrl, productFrontViewImageUrl, productBackViewImageUrl, destroyImageUrl} = this.productImgInfo
      if (this.ticketDetail.status === 2) {
        if (!bnCodeImageUrl || !productFrontViewImageUrl || !productBackViewImageUrl) {
          this.$message.warning('Product pictures are required');
          return
        }

        this.uploadLoading = true

        const url = PAGECONF.imgApi
        this.$http.post('/platform/admin/ticketAdminManage/v1/updateProductImage', {
          ticketId: id,
          bnCodeImageUrl: bnCodeImageUrl.replace(url, this.ChinaS3Url),
          productFrontViewImageUrl: productFrontViewImageUrl.replace(url, this.ChinaS3Url),
          productBackViewImageUrl: productBackViewImageUrl.replace(url, this.ChinaS3Url)
        }).then(res => {
          if (res.code === 0) {
            this.getTicketDetail(this.id)
          }
        }).finally(() => { 
          this.uploadLoading = false
        })
      } else {  // status === 4
        if (!destroyImageUrl) { 
          this.$message.warning('destroyImage is required');
          return
        }
        this.uploadLoading = true
        this.$http.post('/platform/admin/ticketAdminManage/v1/updateDestroyImage', {
          ticketId: id,
          destroyImageUrl
        }).then(res => {
          if (res.code === 0) {
            this.getTicketDetail(this.id)
          }
        }).finally(() => { 
          this.uploadLoading = false
        })
      }
    },
    handleUploadSuccess (url, key) {
      this.productImgInfo[key] = url
    },
    timeFormatUSA,
    initCityOption () {
      this.cityOptionList = cityList.slice(0, 10)
      this.cityOptionPage = 1
    },
    initStateOption () {
      this.stateOptionList = stateList.slice(0, 10)
      this.stateOptionPage = 1
    },
    handleSearchCity (e) {
      this.cityOptionList = cityList.filter(item => item.toUpperCase().includes(e.toUpperCase())).slice(0, 50)
    },
    handleSearchState (e) {
      this.stateOptionList = stateList.filter(item => item.toUpperCase().includes(e.toUpperCase())).slice(0, 50)
    },
    handleCityScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.cityOptionPage*10 + 1 > cityList.length) {
          return
        }
        if ((this.cityOptionPage + 1)*10 <= cityList.length - 1) {
          this.cityOptionList.push(...cityList.slice(this.cityOptionPage*10, (this.cityOptionPage + 1)*10))
        } else {
          this.cityOptionList.push(...cityList.slice(this.cityOptionPage*10))
        }
        this.cityOptionPage ++
        console.log('city渲染总量%d', this.cityOptionList.length)
      }
    },
    handleStateScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.stateOptionPage*10 + 1 > stateList.length) {
          return
        }
        if ((this.stateOptionPage + 1)*10 <= stateList.length - 1) {
          this.stateOptionList.push(...stateList.slice(this.stateOptionPage*10, (this.stateOptionPage + 1)*10))
        } else {
          this.stateOptionList.push(...stateList.slice(this.stateOptionPage*10))
        }
        this.stateOptionPage ++
        console.log('state渲染总量%d', this.stateOptionList.length)
      }
    },
    async showEditModal (opr, row) {
      const isPermision = await btnIsPermissions('editTicketRoles')
      if (!isPermision) return

      this.currentRow = row
      this.editModalType = opr
      this.editSkuId = ''
      this.editModalVisible = true
    },
    addOrUpdateSkuId () {
      if (!this.editSkuId) {
        this.$message.warn('please enter sku ID')
        return
      }
      if (this.skuidDisable) return
      this.skuidDisable = true
      if (this.editModalType === 'Add') {
        this.$http.post('/platform/admin/ticketAdminManage/v1/addTicketShippingSkuInfo', {
          ticketId: this.id,
          skuId: this.editSkuId
        }).then(res => {
          this.$message.success('add success')
          this.editModalVisible = false
          // this.getTicketDetail(this.id)
          setTimeout(() => {
            location.reload()
          }, 1000)
        }).finally(() => { 
          this.skuidDisable = false
        })
      } else {
        this.$http.post('/platform/admin/ticketAdminManage/v1/updateTicketShippingSkuInfo', {
          shippingSkuInfoId: this.currentRow.id,
          skuId: this.editSkuId
        }).then(res => {
          this.$message.success('update success')
          this.editModalVisible = false
          setTimeout(() => {
            location.reload()
          }, 1000)
          // this.getTicketDetail(this.id)
        }).finally(() => { 
          this.skuidDisable = false
        })
      }
    },
    handleDelReplacement (row) {
      this.$http.post('/platform/admin/ticketAdminManage/v1/deleteTicketShippingSkuInfo', {
        shippingSkuInfoId: row.id
      }).then(res => {
        this.$message.success('delete success')
        this.getTicketDetail(this.id)
      })
    },
    async handleDelete () {
      const isPermision = await btnIsPermissions('cancelTicket')
      if (!isPermision) return

      this.deleteLoading = true
      this.$http.post('/platform/admin/ticketAdminManage/v1/deleteTicket', {
        ticketId: this.id
      }).then(res => {
        this.$message.success('delete success')
        this.$router.push('/ticket/list')
      })
    },
    getReviewList (id) {
      this.$http.post('/platform/admin/ticketAdminManage/v1/listReview', {
        ticketId: id
      }).then(res => {
        this.reviewList = res.data
      })
    },
    async handleUpdateShipInfo () {
      const isPermision = await btnIsPermissions('userData')
      if (!isPermision) return

      const valid = await this.$refs.shipForm.validate()
      if (valid !== true) return
      this.$http.post('/platform/admin/ticketAdminManage/v1/updateTicketShippingInfo', {
        ticketId: Number(this.id),
        ...this.shippingInfo,
        shippingAddressLine2: this.shippingInfo.shippingAddressLine2 || undefined
      }).then(res => {
        this.$message.success('update success')
        this.getTicketDetail(this.id)
      })
    },
    async handleReview () {
      const isPermision = await btnIsPermissions('reviewRoles')
      if (!isPermision) return

      if (this.ticketDetail.status === 1 && this.reviewInfo.reviewResult === 1) {
        const sockRes = await this.$http.post('/platform/admin/ticketAdminManage/v1/checkStock', {ticketId: this.id})
        if (sockRes.data === 0) {
          this.$confirm({
            title: 'Out of stock, do you want to continue modifying?',
            content: '',
            onOk:() => {
              this.reviewHttp()
            },
            onCancel() {},
          });
        } else {
          this.reviewHttp()
        }
      } else {
        this.reviewHttp()
      }
      
    },
    reviewHttp() {
      this.$refs.reviewForm.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.$http.post('/platform/admin/ticketAdminManage/v1/review', {
            ticketId: this.ticketDetail.id,
            ...this.reviewInfo
          }).then(res => {
            this.$message.success('review success')
            this.$router.go(-1)
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false;
        }
      });
    },
    getTicketDetail (id) {
      this.$http.post('/platform/admin/ticketAdminManage/v1/getTicket', {
        ticketId: id
      }).then(res => {
        this.ticketDetail = {...res.data}
        Object.keys(this.shippingInfo).forEach(item => {
          this.shippingInfo[item] = res.data[item]
        })

        Object.keys(this.productImgInfo).forEach(item => {
          this.productImgInfo[item] = res.data[item]
        })

        this.ticketDetail.priority = !!this.ticketDetail.priority

        this.$previewRefresh()

      })
    },
    handleCopy (text) {
      this.$copyText(text).then(e => {
        this.$message.success('copy success')
      }).catch(e => {
        this.$message.error('copy fail')
      })
    },
    handleViewProgress (No) {
      window.open(`https://t.17track.net/en?v=2#nums=${No}`)
    },
    handleTemplateChange (text) {
      if (text.join() === 'Custom') {
        this.reviewInfo.reviewComment = ''
        return
      }
      if (text.length) { 
        this.reviewInfo.reviewComment = text.join('\n\n')
      }
    }
  }
}
</script>

<style scoped lang="less">
  /deep/.ant-select-dropdown-menu {
    max-height: 400px;
  }

.ant-divider-horizontal{
  margin: 12px 0;
}
.mgr8{
  margin-right: 8px;
}
.mgt12 {
  margin-top: 12px;
}
.mgr80 {
  margin-right: 80px;
  margin-top: 20px;
}
.mbt12{
  margin-bottom: 12px;
}
.remark-box{
  width: 260px;
  border-left: 12px solid #F7F9FA;
  padding-left: 20px;
}
.shipForm {
  /deep/ .ant-form-item {
  margin-bottom: 0;
  padding-bottom: 0;
  }
  /deep/ .ant-form-item-label{
    padding: 0;
  }
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.pageWrap{
  padding: 0 20px;
}
.center-btn{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ship-table{
  width: 700px;
}
.opr-block{
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // height: 200px;
  background-color: #e6e6e6;
  z-index: 10;
  padding: 20px 0 20px 20px;
}
/deep/.review-form{
  width: 900px;
  // margin: 0 0 0 210px;
  .ant-form-item{
    margin-bottom: 12px;
  }
}
.form-img{
  display: inline;
  width: 86px;
  height: 90px;
  border: 1px solid #0F3864;
  max-width: 100%;
}
.pic-block{
  display: inline-block;
  height: 250px;
}
</style>
