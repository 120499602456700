<template>
  <div class="remark-list">
    <h3>Remarks record</h3>
    <template v-if="remarklist.length">
      <a-card v-for="item in remarklist" :key="item.id" class="remark-item">
        <template slot="title">
          <div>Operator:</div>
          <div class="break-all">{{item.operatorAccount}}</div>
          <div>{{timeFormatUSA(item.editTime)}}</div>
        </template>
        <template slot="extra" v-if="item.operatorAccount === username && item.status !== 1">
          <a-icon type="form" class="edit-icon" @click="handleEdit(item)"/>
        </template>
        <div v-if="item.classification">
          Classification: {{item.classification}}
        </div><br />
        <p style="word-break: break-all;">{{item.remark}}</p>
        <div>
          state
          <a-select :default-value="item.status" @change="handleChange($event, item.id)" :disabled="permisssionStatus" style="width: 120px">
            <a-select-option :value="0">
              -
            </a-select-option>
            <a-select-option :value="1">
              Completed
            </a-select-option>
          </a-select>
        </div>
      </a-card>
    </template>
    <a-empty v-else description="No data" :image="simpleImage"/>

    <a-modal
      title="Modify Remarks"
      :visible="visible"
      :footer="null"
      :closable="true"
      :maskClosable="true"
      @cancel="() => visible=false"
    >
      <remark-form :ticketId="Number(ticketId)" :remarkItem="currentData" @handleUpdateSuccess="handleSuccess"></remark-form>
    </a-modal>

  </div>
</template>

<script>
import RemarkForm from './RemarkForm.vue'
import { timeFormatUSA } from '@/utils/utils.js'
import { Empty } from 'ant-design-vue';
import { btnPermissionsRoles } from '../../../utils/btnPermissions'

export default {
  components: {
    RemarkForm
  },
  props: {
    remarklist: {
      type: Array,
      default: () => []
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  data  () {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      currentData: {},
      visible: false,
      username: JSON.parse(localStorage.getItem('userInfo'))?.username,
    }
  },
  methods: {
    timeFormatUSA,
    handleEdit (item) {
      this.currentData = { ...item },
      this.visible = true
    },
    handleSuccess () {
      this.visible = false
      this.$emit('handleUpdateSuccess')
    },
    async handleChange (status, id) { 
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/updateRemarkStatus', { status, id })
        if (res.code === 0) { 
          this.$emit('handleUpdateSuccess')
        }
      } catch (error) {
        //
      }
    }
  },
  computed: { 
    permisssionStatus () {
      const rolesList = btnPermissionsRoles.remarkStatus
      const role = Number(JSON.parse(localStorage.getItem('userInfo'))?.role)
      return !rolesList.includes(role)
    }
  }
}
</script>

<style lang='less' scoped>
.remark-list {
  /deep/.ant-card-head-title {
    white-space: normal;
  }
  /deep/ .ant-card-body{
    padding: 12px;
  }
  /deep/.ant-card-head{
    padding: 0 12px;
  }
  .break-all{
    word-break: break-all;
  }
  .edit-icon{
    color: #00C1BC;
    cursor: pointer;
  }
  .remark-item{
    margin-top: 24px;
    height: 180px;
    overflow-y: auto;
  }
}
</style>