<template>
  <a-table :columns="columns" :data-source="editRecordingList" :pagination="false">
    <span slot="editTime" slot-scope="editTime">{{timeFormatUSA(editTime)}}</span>
    <span slot="beforeActionText" slot-scope="beforeModification, row">
      <div v-if="isUrl(row.item, beforeModification)">
        <a :href="beforeModification" target="_blank">{{beforeModification}}</a>
      </div>
      <div v-else>{{beforeModification || '-'}}</div>
    </span>
    <span slot="actionText" slot-scope="afterModification, row">
      <div v-if="isUrl(row.item, afterModification)">
        <a :href="afterModification" target="_blank">{{afterModification}}</a>
      </div>
      <div v-html="afterModification.replace(/\n/g, '<br />') || '-'" v-else></div>
    </span>
  </a-table>
</template>

<script>
import { timeFormatUSA } from '@/utils/utils.js'
export default {
  props: {
    editRecordingList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      columns: [
        {
          title: 'operatorAccount',
          dataIndex: 'operatorAccount'
        },
        {
          title: 'Edit Time',
          dataIndex: 'editTime',
          scopedSlots: { customRender: 'editTime' },
        },
        {
          title: 'item',
          dataIndex: 'item'
        },
        {
          title: 'Before modification',
          dataIndex: 'beforeModification',
          scopedSlots: { customRender: 'beforeActionText' },
        },
        {
          title: 'After modification',
          dataIndex: 'afterModification',
          scopedSlots: { customRender: 'actionText' },
        }
      ]
    }
  },
  methods: {
    timeFormatUSA,
    isUrl (name, beforeModification) { 
      return ['PICTURE OF UNDERSIDE', 'PICTURE OF FRONT', 'PICTURE OF Back'].includes(name) && beforeModification && beforeModification !== '-'
    }
  }
}
</script>