<template>
  <div class="pro-info">
    <h4>PRODUCT INFORMATION</h4>
    <div class="info-item">
      <div>MODEL ID:</div>
      <div>{{ticketDetail.modelId}}</div>
    </div>
    <div class="info-item">
      <div>COLOR:</div>
      <div>{{ticketDetail.color}}</div>
    </div>
    <div class="info-item">
      <div>BATCH NUMBER (B/N):</div>
      <div>{{ticketDetail.bnCodeLine1}}</div>
      <div>{{ticketDetail.bnCodeLine2}}</div>

      <div v-if="ticketDetail.repeatTicketInfoList && ticketDetail.repeatTicketInfoList.length">
        <div style="color: red;margin-top: 12px;">WARNING: Duplicate B/N code!</div>
        <!-- <div>相关工单：点击可查看</div> -->
        <a-button style="display:block; padding: 0" type="link" @click="handleShowBnModal">check the details</a-button>
        <!-- <a-button style="display:block; padding: 0" @click="handleGoTicket(item.ticketId)" type="link" v-for="(item, index) in ticketDetail.repeatTicketInfoList" :key="index">{{item.ticketNo}}</a-button> -->
      </div>
    </div>
    <duplicate-b-n-dialog ref="duplicateBN"></duplicate-b-n-dialog>
  </div>
</template>

<script>

import DuplicateBNDialog from './DuplicateBNDialog.vue'
export default {
  components: {DuplicateBNDialog},
  props: {
    ticketDetail: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleShowBnModal () { 
      this.$refs.duplicateBN.show(this.ticketDetail.repeatTicketInfoList)
    }
    // handleGoTicket (id) {
    //     const routeUrl = this.$router.resolve({
    //     path:`/ticket/detail/${id}`
    //   })
    //   window.open(routeUrl.href, '_blank')
    // }
  }
}
</script>

<style lang='less' scoped>
.pro-info{
  min-height: 180px;
  .info-item{
    margin-top: 12px;
    div{
      line-height: 1;
    }
  }
}

</style>